// React
import React from "react";
import PropTypes from "prop-types";

// Components
import Link from "../Link";

// Material ui
import {makeStyles} from "@material-ui/core/styles";
import MuiLink from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

// Other libs
import classNames from "classnames";
import * as routes from "../../constants/routes";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100vh",
    display: "flex",
    alignItems: "center" /* horizontal */,
    justifyContent: "center" /* vertical */,
    background: "#f6f7fb",
  },
  content: {
    width: 400,
    maxWidth: "90%",
    margin: "auto",
    textAlign: "center",
  },
  loginCard: {
    marginBottom: 20,
  },
  mainCtaButton: {
    width: "100%",
    marginTop: 5,
    marginBottom: 0,
  },
  mainHeaderText: {
    fontSize: "1.6rem",
    fontWeight: 500,
    textAlign: "center",
    padding: 0,
    marginTop: 20,
  },
  gridContainer: {
    margin: "auto",
    width: "inherit",
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 5,
  },
  logo: {
    height: 32,
    marginBottom: theme.spacing(3),
  },
}));

export default function View(props) {
  const {
    email,
    password,
    errorMessages,
    handleChange,
    handleLoginClick,
    togglePasswordResetDialog,
  } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <img
          alt="Maxer logo full"
          className={classes.logo}
          src="https://images.ctfassets.net/h6w739vsk2p5/1ynYezPX2cmyxd0twklHIC/66f8c80540ba413e31281a96572ca653/maxer-logo-full.svg"
        />
        <form className={classes.form} autoComplete="off">
          <Paper className={classes.loginCard}>
            <Grid container spacing={1} className={classes.gridContainer}>
              <Grid item xs={12}>
                <Typography
                  className={classes.mainHeaderText}
                  component="h1"
                  variant="h3"
                  gutterBottom
                >
                  Log in
                </Typography>
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  id="email"
                  className={classNames(classes.textField, classes.dense)}
                  margin="dense"
                  label="Email"
                  fullWidth={true}
                  value={email || ""}
                  onChange={handleChange("email")}
                  variant="outlined"
                  error={errorMessages.email}
                  helperText={errorMessages.email && errorMessages.email}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  id="password"
                  className={classNames(classes.textField, classes.dense)}
                  margin="dense"
                  label="Password"
                  fullWidth={true}
                  type="password"
                  value={password || ""}
                  onChange={handleChange("password")}
                  variant="outlined"
                  error={Boolean(errorMessages.password)}
                  helperText={errorMessages.password && errorMessages.password}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Button
                  id="login-button"
                  variant="contained"
                  color="primary"
                  className={classes.mainCtaButton}
                  onClick={handleLoginClick}
                >
                  Log in
                </Button>
              </Grid>

              <Grid item xs={12} sm={12} style={{textAlign: "center"}}>
                <MuiLink
                  id="pw-reset-link"
                  onClick={togglePasswordResetDialog}
                  color="primary"
                  weight="bold"
                >
                  Forgot password?
                </MuiLink>
              </Grid>

            </Grid>
          </Paper>
          <div>
            <Typography variant="body1" align="center">
              Don't have an account?{" "}
              <Link to={routes.signup} color="primary" weight="bold">
                Sign up
              </Link>
            </Typography>
          </div>
        </form>
      </div>
    </div>
  );
}

View.propTypes = {
  email: PropTypes.string,
  password: PropTypes.string,
  errorMessages: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleLoginClick: PropTypes.func.isRequired,
  togglePasswordResetDialog: PropTypes.func.isRequired,
};
