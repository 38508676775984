// React
import React from "react";
import {useLocation, navigate} from "@reach/router";

// Context]
import {AuthUserContext} from "../Session";
import {FirebaseContext} from "../Firebase";

// Page components
import View from "./View";
import ResetPasswordDialog from "./ResetPasswordDialog";
import SimpleDialog from "../SimpleDialog";
import LoadingIndicator from "../LoadingIndicator";

// Other libs
import * as yup from "yup";
import queryString from "query-string";
import * as routes from "../../constants/routes";

const initialErrorMessages = {
  email: null,
  password: null,
  signInError: null,
};

const loginFieldsSchema = yup.object({
  email: yup.string().required().email(),
  password: yup.string().required(),
});

function Login(props) {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [errorMessages, setErrorMessages] = React.useState(initialErrorMessages);
  const [showPwResetDialog, setShowPwResetDialog] = React.useState(false);
  const [showResetConfirmedDialog, setShowResetConfirmedDialog] = React.useState(false);

  const [isLoggingIn, setIsLoggingIn] = React.useState(false);
  const location = useLocation();
  const parsedQueries = queryString.parse(location.search);
  const firebase = React.useContext(FirebaseContext);
  const authUser = React.useContext(AuthUserContext);

  React.useEffect(() => {
    if (authUser.hasAuthenticated) {
      return navigate(routes.app);
    }
  }, [authUser.hasAuthenticated])

  React.useEffect(() => {
    setEmail(parsedQueries.email);
  }, [parsedQueries.email]);

  const togglePwResetDialog = () => {
    console.log("togglePwResetDialog")
    setShowPwResetDialog(value => !value);
  };

  const togglePwResetConfirmedDialog = () =>
    setShowResetConfirmedDialog((value) => !value);

  const handleChange = (prop) => (event) => {
    Boolean(prop === "email") && setEmail(event.target.value);
    Boolean(prop === "password") && setPassword(event.target.value);
  };

  const resetErrorMessages = () =>
    setErrorMessages({
      email: null,
      password: null,
      signInError: null,
    });

  const handleLoginClick = () => {
    setIsLoggingIn(true);
    validateFields()
      .then((isValid) =>
        isValid
          ? firebase.auth
          .signInWithEmailAndPassword(email, password)
          .then((user) => {
            let entryRoute = routes.app;
            if (parsedQueries.redirect) {
              entryRoute = decodeURIComponent(parsedQueries.redirect);
            }
            return navigate(entryRoute);
            // return navigate(entryRoute);
          })
          .catch((error) => {
            const newErrorMessages = errorMessages;
            newErrorMessages.signInError = error.message;
            setIsLoggingIn(false);
            setErrorMessages(newErrorMessages);
          })
          : console.log("Login error")
      );
  };

  const validateFields = () => {
    return new Promise((resolve, reject) => {
      // Reset errorMessages state
      resetErrorMessages();
      loginFieldsSchema
        .validate({email, password}, {abortEarly: false})
        .then((result) => {
          resolve(true);
        })
        .catch((error) => {
          const errorInit = error;
          const errorMessageCopy = {
            email: "Enter a valid email",
            password: "Enter a valid password",
          };
          // Gather error messages to show in form fields
          const errorMessages = {};
          for (let i = 0; i < errorInit.inner.length; i++) {
            const error = errorInit.inner[i];
            errorMessages[error.path] = errorMessageCopy[error.path];
          }
          setIsLoggingIn(false);
          setErrorMessages(errorMessages);
          reject();
        });
    });
  };

  const handlePasswordReset = () => {
    togglePwResetDialog();
    firebase.auth.sendPasswordResetEmail(email).catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      if (errorCode === "auth/invalid-email") {
        alert(errorMessage);
      } else if (errorCode === "auth/user-not-found") {
        alert(errorMessage);
      }
    });
    togglePwResetConfirmedDialog();
  };

  if (authUser.isAuthenticating) {
    return <LoadingIndicator message="Loading..."/>;
  }
  if (authUser.hasAuthenticated || isLoggingIn) {
    return <LoadingIndicator message="Redirecting to app..."/>;
  }

  return (
    <div>
      {/* Dialog popping up for invalid email/password combo's*/}
      <SimpleDialog
        id="login-error-dialog"
        toggleDialog={resetErrorMessages}
        open={Boolean(errorMessages.signInError)}
        title="Oops... We weren't able to sign you in"
        description={errorMessages.signInError || ""}
        primaryActionText="OK"
        handlePrimaryAction={resetErrorMessages}
      />
      <ResetPasswordDialog
        open={showPwResetDialog}
        toggleDialog={handlePasswordReset}
        toggle={togglePwResetDialog}
        email={email}
        handleChange={handleChange}
        handlePasswordReset={handlePasswordReset}
      />
      {/* Dialog for confirming password has been reset */}
      <SimpleDialog
        open={showResetConfirmedDialog}
        toggleDialog={togglePwResetConfirmedDialog}
        title="Check your inbox"
        description="We've send you an email with a password reset link"
        primaryActionText="OK"
        handlePrimaryAction={togglePwResetConfirmedDialog}
      />
      <View
        email={email}
        password={password}
        errorMessages={errorMessages}
        handleChange={handleChange}
        handleLoginClick={handleLoginClick}
        togglePasswordResetDialog={togglePwResetDialog}
      />
    </div>
  );
}

export default Login;
