// React
import React from "react";
import PropTypes from "prop-types";

// Material ui
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function ResetPasswordDialog(props) {
  const {open, email, toggle, handleChange, handlePasswordReset} = props;

  return (
    <Dialog open={open} onClose={toggle} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Reset your password</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Enter the email address associated with your account, and we’ll email
          you a link to reset your password.
        </DialogContentText>
        <TextField
          id="email"
          label="Email"
          value={email || ""}
          onChange={handleChange("email")}
          variant="outlined"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={toggle} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handlePasswordReset}
          color="primary"
          variant="contained"
        >
          Reset password
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ResetPasswordDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  email: PropTypes.string,
  toggle: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handlePasswordReset: PropTypes.func.isRequired,
};
