import React from "react";

// Context
import {withAuthentication} from "../components/Session";

// Pages
import Login from "../components/Login";

function LoginView() {
  return <Login/>;
}

export default withAuthentication(LoginView);
